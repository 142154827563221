@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.navigation-header-item {
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--navigation-text-color);
  border-radius: 4px;
  position: relative;
  font-weight: 500;

  &:hover {
    background-color: var(--navigation-hover-color);
  }

  &:focus,
  &:active,
  &.highlighted {
    background-color: var(--navigation-highlight-color);
  }

  &__badge {
    position: absolute;
    top: 2px;
    right: -2px;
  }
}

.navigation-header-item--primary {
  &:focus,
  &:active,
  &.highlighted {
    background-color: var(--button-color);
  }
}

.navigation-header-item--outlined {
  border: 2px solid var(--navigation-text-color);
}
