@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.main-content-container {
  position: fixed;
  height: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;
  top: var(--navigation-height);
  left: 0;

  #main-content {
    height: calc(100% - var(--navigation-height));
    flex: 1;
    overflow: scroll;
  }
}

.side-navigation {
  background-color: var(--navigation-color);
  color: var(--navigation-text-color);
  width: var(--side-navigation-width);
  overflow: hidden;
  transition: width 0.3s ease-in;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.8);
  z-index: var(--side-navigation-z-index);

  &::after {
    content: '';
    transition: left 0.3s ease-in;
    position: absolute;
    background-color: transparent;
    left: var(--side-navigation-width);
    height: 25px;
    width: 10px;
    border-top-left-radius: 12px;
    box-shadow: 0 -10px 0 0 var(--navigation-color);
    transition: width 0.3s ease-in;
  }
}

.side-navigation--white {
  &::after {
    border-top: 2.4px solid rgba(0, 0, 0, 0.23);
  }
}

.side-navigation--loaded {
  display: flex;
  max-height: calc(100vh - var(--navigation-height));
}

.side-navigation--expanded {
  min-width: var(--side-navigation-width-expanded);
  max-width: calc(var(--side-navigation-width-expanded) * 2);
  width: auto;

  &::after {
    left: var(--side-navigation-width-expanded-dynamic);
  }
}

.side-navigation--sub-nav-active {
  box-shadow: none;
}

.side-navigation__navigation {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;

  .navigation-link__label,
  .navigation-button__label {
    font-weight: 500;
    transition: opacity 0.6s ease-in-out;
    opacity: 1;

    &.visually-hidden {
      opacity: 0 !important;
    }
  }
}

%divider {
  position: absolute;
  content: '';
  border-bottom: 1px solid var(--navigation-text-color);
  width: 85%;
  top: 0;
  left: 50%;
  transform: translateX(-50%) scaleY(0.5);
  transform-origin: bottom;
}

.side-navigation__list {
  position: relative;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
  margin: 0 0 1rem;
  list-style-type: none;
  flex-basis: 0;
  &:not(:first-of-type) {
    padding: 1rem 0 0;
  }
  &:not(:first-of-type):before {
    @extend %divider;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.side-navigation__list--spacer {
  margin-top: auto;
  &:not(:first-of-type):before {
    content: none;
  }
}

.side-navigation__list--add-new {
  flex: 0;
  margin-bottom: 2rem;
}

.side-navigation__primary-navigation-groups {
  display: flex;
  flex-direction: column;
}

.side-navigation__navigation-item {
  position: relative;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 1rem;
}

.side-navigation__collapse-button,
.side-navigation__settings-button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--side-navigation-icon-spacing);
  padding: var(--side-navigation-item-padding);
  border-radius: 4px;
  width: 100%;
  font-weight: 500;
}

.side-navigation__collapse-button:hover {
  background-color: var(--navigation-hover-color);
}
