@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.top-navigation-bar {
  min-height: var(--navigation-height);
  color: var(--navigation-text-color);
  background-color: var(--navigation-color);
  z-index: 1030;

  &__company-logo {
    width: calc(var(--side-navigation-width) - 2.4rem);

    &--expanded {
      width: calc(var(--side-navigation-width-expanded-dynamic) - 2rem);
      max-width: calc(var(--side-navigation-width-expanded-dynamic) * 2 - 2rem);
    }
  }

  //Temp fixes for company switcher
  :deep(.hidden-nav-switcher) {
    display: flex;
  }
  :deep(.hidden-nav-switcher .navbar__link--dropdown) {
    color: var(--navigation-text-color);
    font-size: inherit;
    padding: 1rem !important;
    color: var(--navigation-text-color);
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--navigation-hover-color);
    }
  }
}

.top-navigation-bar--white {
  &::after {
    content: '';
    position: absolute;
    top: calc(var(--navigation-height) - 10px);
    left: calc(var(--side-navigation-width) + 5px);
    min-width: 100%;
    height: 1px;
    background-color: transparent;
    box-shadow: 4.5px 10.4px 2px rgba(0, 0, 0, 0.8);
  }
}

.top-navigation-bar--white-expanded {
  &::after {
    left: calc(var(--side-navigation-width-expanded-dynamic) + 5px);
  }
}

.top-navigation-bar__company-logo-image {
  height: 35px;
  width: calc(var(--side-navigation-width) - 2.4rem);
  border-radius: 100%;
}

.top-navigation-bar__company-custom-logo-image {
  height: 4.2rem;
  max-width: 100%;
  border-radius: 10px;
}

.top-navigation-bar__spacer {
  display: flex;
  flex: 1;
}

.top-navigation-bar__quick-search {
  position: relative;
  display: flex;
  flex: 1;
  flex-basis: max-content;
  margin-right: auto;
}

.top-navigation-bar__quick-search-results-container {
  position: absolute;
  top: 44px;
  left: 0;
  width: 100%;
  max-width: 500px;
  min-width: 300px;
  border-radius: 12px;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - var(--navigation-height));
  overflow: hidden;
  display: flex;
}
