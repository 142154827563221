@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

@import '@/styles/_user-menu-banner.scss';
$user-menu-width: 200px;

.user-account-menu {
  color: inherit;
  margin-left: 3rem;
  position: relative;

  &__full-name {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: $user-menu-width - 17px;
  }

  &__popover-menu {
    padding-top: 0;
    position: fixed;
    top: var(--navigation-height);
    right: 14px;
    max-width: $user-menu-width;
  }

  .linked-accounts-switcher {
    width: 100%;
    margin-top: 0.5rem;

    // TODO: move to switcher SCSS when old nav is fully removed
    :deep(.linked-accounts-switcher__company-image) {
      border: 2px solid var(--user-menu-gradient-text-color);
    }
    :deep(.base-button--theme-brand) {
      --base-button-background-color: var(--user-menu-gradient-text-color);
    }
  }
}

.user-account-menu-full-description {
  &__company-info-wrapper {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
  }

  &__company-logo {
    display: inline-flex;
    margin-right: 0.5rem;
    border: 1px solid $cool-grey;
    border-radius: 100%;

    img {
      height: 16px;
      width: 16px;
      border-radius: 100%;
    }
  }
}
