.user-account-menu__banner {
  flex-wrap: wrap;
  padding: 1rem 2.5rem;
  background: linear-gradient(
    to bottom right,
    var(--user-menu-gradient-start-color),
    var(--user-menu-gradient-end-color)
  );
  color: var(--user-menu-gradient-text-color);
  font-size: 16px;

  :deep(.avatar-full-description__avatar) {
    border: 2px solid white;
    margin: 0 1rem 0 0;
  }
}
