@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.navigation-button__button {
  all: unset;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: var(--side-navigation-item-padding);
  gap: var(--side-navigation-icon-spacing);
  text-wrap: nowrap;
  border-radius: 4px;
  align-items: center;

  &:hover,
  &--focused {
    background-color: var(--navigation-hover-color);
  }

  &--highlighted,
  &--highlighted:hover {
    background-color: var(--navigation-highlight-color);
    font-weight: bold;
  }

  .navigation-button__label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
