@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.burger-menu__button {
  border: none;
  background: none;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 30px;
}

.burger-menu__line {
  background-color: var(--navigation-text-color);
  height: 1px;
  width: 100%;
  transition: all 0.3s ease-out;
}

.burger-menu__button--open {
  .burger-menu__line {
    &:nth-child(1) {
      // transform: rotate(45deg) translate(5px, 5px);
      transform: rotate(45deg) translate(7px, 7px);
    }
    &:nth-child(2) {
      opacity: 0;
    }
    &:nth-child(3) {
      // transform: rotate(-45deg) translate(7px, -7px);
      transform: rotate(-45deg) translate(6px, -6px);
    }
  }
}
