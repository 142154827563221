@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.quick-search-results__heading {
  color: $royal-blue;
  font-size: 1.5rem;
  padding: 2rem 2rem 0 1rem;
  font-weight: 600;
}

ul,
li {
  all: unset;
}

.quick-search-results__link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background-color: $platinum;
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 4px;
  }
}

.quick-search-results__link-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  color: $black;
  width: 100%;
  overflow: hidden;
  &-name {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    color: #333333;
  }
}

.quick-search-results__total-link {
  display: flex;
  align-items: center;
  margin-left: auto;
  width: max-content;
  gap: 1rem;
  padding: 1rem;
  border-radius: 4px;
  text-decoration: none;

  &:hover {
    background-color: $platinum;
  }
}
