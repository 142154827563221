@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.search-input__container {
  position: relative;
  max-width: 500px;
  min-width: 200px;
  &.full-width {
    max-width: 100%;
  }

  &--type-discover-content {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-shrink: 0;
    height: 30px;
  }

  &--dropdown {
    .search-input__icon:before {
      margin: 4px 0 0 8px;
      line-height: 36px;
    }

    button {
      padding: 10px;
    }
    .search-input__input {
      padding-left: 30px;
    }
  }

  .dropdown-menu & {
    position: sticky;
    top: 0;
    z-index: 1;
    margin: 8px 5px;
  }
}

.search-input__input {
  pointer-events: all;
  padding: 15px 20px 15px 45px;
  height: 42px;
  border-radius: 10px;
  padding: 12px 15px 12px 35px;
  width: 100%;
  @include form-control-focus($input-border-focus);
  box-shadow: none;
  border: none;

  :disabled {
    color: $text-disabled;
  }

  &--rounded {
    border-radius: 200px;
  }

  &--bordered {
    border: 1px solid $mid-gray;
  }

  &--slim {
    height: 35px;
    padding: 10px 15px 10px 35px;
  }
}

.search-input__icon {
  top: 0;
  left: 0;

  &:after {
    content: none;
  }

  &:before {
    pointer-events: none;
    cursor: none;
    position: absolute;
    font-family: 'ic';
    font-size: 16px;
    content: $ic-search;
    margin: 10px 0 0 12px;

    .search-input__container--slim & {
      margin: 6px 0 0 12px;
    }
  }

  &--search {
    color: $gray-light;
  }

  &--clear {
    &:before {
      pointer-events: all;
      cursor: pointer;
      content: $ic-error-cross;
    }

    &:after {
      content: none;
    }
  }
}

button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px 20px;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
