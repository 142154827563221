@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

$min-width: 240px;
$max-width: 300px;
$popover-zindex: 1000;
$popover-offset: calc(100% + 0.5rem);

.popover-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: $max-width;
  background: $white;
  position: absolute;
  z-index: $popover-zindex;
  min-width: $min-width;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15), 0px 5px 16px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem 0;
  border-radius: 4px;

  &--bottom {
    top: 100%;
    right: 0;
    border-radius: 0 0 4px 4px;
  }

  &--left,
  &--right {
    top: 0;
    z-index: $popover-zindex - 1;
  }

  &--left {
    right: $popover-offset;
    clip-path: inset(0 0 -20px -20px);
  }

  &--right {
    left: $popover-offset;
    clip-path: inset(0 -20px -20px 0);
  }
}
