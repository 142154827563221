@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.navigation-link__link {
  display: flex;
  width: 100%;
  padding: var(--side-navigation-item-padding);
  gap: var(--side-navigation-icon-spacing);
  text-decoration: none;
  border-radius: 4px;
  align-items: center;

  &:hover {
    background-color: var(--navigation-hover-color);
  }

  &:link,
  &:visited,
  &:active {
    color: var(--navigation-text-color);
  }
}

.navigation-link__link--compact {
  padding: 0.5rem 1rem;
}

.navigation-link__link--light {
  &:link,
  &:visited,
  &:hover,
  &:active {
    color: #000000;
  }
  &:hover,
  &:active {
    background-color: $platinum;
  }
}

.navigation-link__link--highlighted,
.navigation-link__link--highlighted:hover {
  background-color: var(--navigation-highlight-color);
  font-weight: bold;
}

.navigation-link__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
