@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

$hover-color: $platinum;

.recent-search-results {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.recent-search-results__link {
  flex: 1;
  border-radius: 4px;
  padding: 1rem;

  &:hover {
    background-color: $hover-color;
  }
}

.recent-search-results__remove {
  display: flex;
  justify-content: center;
  align-items: center;

  color: $link-color;
  border-radius: 4px;
  width: 3rem;
  height: 3rem;

  &:hover {
    background-color: $hover-color;
  }
}
