@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.add-new__popover-menu {
  color: $jet;
}

.add-new__popover-menu-content {
  padding: 1rem 3rem 1.5rem;
}

.add-new__title {
  display: block;
  font-size: 1.8rem;
  font-weight: 600;
  padding-bottom: 1rem;
}

.add-new__link-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
}

.add-new__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 1rem 2rem;
  font-size: 1.6rem;
  font-weight: 600;
  border-radius: 4px;
  color: $jet;

  &:hover {
    background-color: #dae9f7 !important;
  }
}

.add-new__link__icon {
  background-color: #f5faff;
  margin-right: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0;
  border-radius: 4px;
}
