@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.subnav-popover {
  background-color: var(--navigation-color);
  color: var(--navigation-text-color);
}

.subnav-popover__container {
  &::-webkit-scrollbar {
    background-color: var(--navigation-color);
    width: 1.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--navigation-hover-color);
  }
}

.subnav-popover__header,
.subnav-popover-menu-group__header,
.subnav-popover-menu-group__back-button {
  color: var(--navigation-text-color);
}
