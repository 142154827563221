@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.chevron {
  clip-path: polygon(40% 0%, 90% 50%, 40% 100%, 24% 85%, 60% 50%, 25% 15%);

  &.left {
    transform: rotate(180deg);
  }
  &.up {
    transform: rotate(-90deg);
  }
  &.down {
    transform: rotate(90deg);
  }

  &.small {
    width: 1rem;
    height: 1rem;
  }
  &.medium {
    width: 2rem;
    height: 2rem;
  }
  &.large {
    width: 3rem;
    height: 3rem;
  }
}
