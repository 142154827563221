@import '~bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_center-block.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_hide-text.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_clearfix.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/_utilities.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_forms.scss';
          @import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins/_vendor-prefixes.scss';
          @import 'app/assets/stylesheets/variables/_media_queries.scss';
          @import 'app/assets/stylesheets/variables/_old_colours.scss';
          @import 'app/assets/stylesheets/variables/_colours.scss';
          @import 'app/assets/stylesheets/variables/_icon_definitions.scss';
          @import 'app/assets/stylesheets/components/_item_search.scss';
          @import 'app/assets/stylesheets/components/search/_search_input.scss';

.navigation-tray__tray {
  position: fixed;
  top: var(--navigation-height);
  left: 0;
  right: 0;
  height: calc(100vh - var(--navigation-height));
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 10;
  background-color: var(--navigation-color);
  color: var(--navigation-text-color);
  scrollbar-width: none; /* Firefox */
}

.navigation-tray__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0 0 0;
}

.navigation-tray__tray::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.navigation-tray__tray--light {
  background-color: #ffffff;
  color: #333333;
  gap: 14px;

  .navigation-tray__link {
    color: #333333;
  }
}

.navigation-tray__button,
.navigation-tray__link {
  width: 100%;
  text-align: left;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--navigation-text-color);
  text-decoration: none;
}

.navigation-tray__button--back {
  margin-left: 10px;
}

.navigation-tray__link--child {
  color: #333333;
}

.navigation-tray__icon {
  font-size: 2rem;
  margin: 0 12px 0 0;
}

.navigation-tray__icon--header {
  margin: 0 12px 0 6px;
}

.navigation-tray__avatar {
  margin-right: 10px;
  & ::v-deep span {
    border: 1px solid var(--navigation-text-color);
  }
}

.navigation-tray__divider {
  border: 0;
  display: block;
  width: 100%;
  background-color: #b8c2cc;
  height: 1px;
  margin: 6px 0;
}

.navigation-tray__add-new-group-title {
  font-size: 1.6rem;
  font-weight: 600;
}

#subnavs {
  position: relative;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.8s;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}
